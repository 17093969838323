import { graphql, Link } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../components/Layout/Layout';
import styles from './contact-us-submitted.module.scss';

export const contactUsPageQuery = graphql`
  query ContactUsSubmittedPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const ContactUsSubmittedPage: React.FC = () => {
  const { t, originalPath, i18n } = useI18next();

  return (
    <Layout
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      SEOProps={{
        meta: [
          { name: 'robots', content: 'noindex, follow' },
          { name: 'googlebot', content: 'noindex, follow' },
        ],
      }}
      wrapperClassName={styles.mainWrapper}
    >
      <div className={styles.main}>
        <h1
          dangerouslySetInnerHTML={{ __html: t('contact-us-submitted-title') }}
        />
        <p>{t('contact-us-submitted-description')}</p>

        <Link to="/" type="button">
          {t('ok')}
        </Link>
      </div>
    </Layout>
  );
};

export default ContactUsSubmittedPage;
